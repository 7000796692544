export default theme => ({
    root: {
        '&:hover': { backgroundColor: 'black' },
        '& span': { fontWeight: 'bold' },
        minWidth: '15rem',
        minHeight: '4rem',
        alignSelf: 'stretch',
        paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
    }
})